<template>
  <div>
    <div class="vx-row pb-10">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        class=""
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl font-bold fontSizeMedium">
        {{ $t("DoctorProfile") }}
      </p>
    </div>
    <div class="mr-10">
      <div class="vx-row w-full" style="margin: auto">
        <div class="vx-col mt-4 con-img lg:w-1/2 w-full">
          <h2 class="mb-6">
            {{ $t("Dr") }}/
            <span v-if="!$vs.rtl">{{ Doctor.FullName }}</span>
            <span v-if="$vs.rtl" > {{ Doctor.NameAR }} </span>
          </h2>

          <!-- <imageLazy
            :imageStyle="{
              'border-radius': '20px',
              height: '200px',
              width: '14vw',
              'object-fit': 'fill',
            }"
            :imageClass="'doctorImage'"
            :src="baseURL + Doctor.ProfileImagePath"
            placeHolderType="doctor"
          /> -->
          <imageLazy
            :imageStyle="{ float: 'right', 'border-radius': '20px','height': '100%', 'width': '100%', 'object-fit': 'fill'}"
            :imageClass="'doctorImage'"
            :src="baseURL + Doctor.ProfileImagePath"
            placeHolderType="doctor"
          />

          <!--
          <img
            v-else
            :src="baseURL + 'Uploads/docotrGeneral.jpg'"
            alt="user-img"

            height="150"
            class="lg:w-2/3 w-full rounded-full shadow-md cursor-pointer block m-4"
          /> -->
        </div>

        <div class="vx-col mt-5 lg:w-1/2 w-full">
          <div div class="w-full justify-center">
            <h5 class="mt-8" v-if="Doctor.Country">
              {{ $t("Destination") }} : {{ Doctor.Country.Name }}
            </h5>
            <h5 class="mt-4">{{ $t("Title") }} : {{ Doctor.Title }}</h5>
            <h5 class="mt-4" v-if="Doctor.Address">
              {{ $t("Address") }} : {{ Doctor.Address }}
            </h5>
            <h5 class="mt-4 mb-3">{{ $t("Biography") }} : {{ Doctor.Bio }}</h5>
            <h6>
              <a
                 v-if="Doctor.CVPath !=null"
                style="border-radius: 3px"
                class="text-white bg-primary p-2"
                :href="baseURL + Doctor.CVPath"
                target="_blank"
              >
                {{ $t("CV") }}
              </a>
            </h6>
            <vs-button
              v-if="showAppointment"
              class="justify-center mt-4"
              @click="$router.push('/AppointmentSearch/' + Doctor.ID)"
            >
              {{ $t("OnlineAppointment") }}
            </vs-button>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <h2
            class="justify-center mt-8 ml-2 mb-4 greenColor"
            v-show="Doctor.DoctorSpecialities != 0"
          >
            {{ $t("DoctorSpecilties") }}
          </h2>
          <div class="ml-2 mt-4">
            <li
              class="mt-2"
              style="color: black"
              v-for="specialty in Doctor.DoctorSpecialities"
              :key="specialty.ID"
            >
              <span v-if="specialty.Specialty"></span>
              {{ specialty.Specialty.Name }}
            </li>
          </div>
        </div>

        <div class="vx-col lg:w-1/2 w-full" style="width:300px">
          <h2
            class="justify-center mt-8 ml-2 mb-4 greenColor"
            v-show="Doctor.DoctorExperiences != 0"
          >
            {{ $t("DoctorExperiences") }}
          </h2>
                        <div class="ml-2 mt-4" style="overflow: hidden">
                  <li
                    class="mt-2"
                    style="
                      color: black;
                      white-space: normal;
                      overflow-wrap: break-word;
                    
                    "
                    v-for="exp in Doctor.DoctorExperiences"
                    :key="exp.ID"
                  >
                    {{ $t("Title") }}: {{ exp.TitleEN }} , {{ $t("ReferenceArea") }}:
                    {{ exp.ReferenceAreaEN }}
                  </li>
                </div>
        </div>

        <!-- Doctor Education -->
<div class="vx-col lg:w-1/2 w-full" style="width:300px">
          <h2
            class="justify-center mt-8 ml-2 mb-4 greenColor"
            v-show="Doctor.DoctorEducations != 0"
          >
            {{ $t("Education") }}
          </h2>
                        <div class="ml-2 mt-4" style="overflow: hidden">
                  <li
                    class="mt-2"
                    style="
                      color: black;
                      white-space: normal;
                      overflow-wrap: break-word;
                    
                    "
                    v-for="exp in Doctor.DoctorEducations"
                    :key="exp.ID"
                  >
                    {{ $t("EducationDegree") }}: {{ exp.TitleEN }} , {{ $t("EducationArea") }}:
                    {{ exp.ReferenceAreaEN }} ({{ $t("From") }}: {{ formatMonthYear(exp.DateFrom) }},{{ $t("To") }}: {{ formatMonthYear(exp.DateTo) }})
                  </li>
                </div>
        </div>

      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <h2
            class="justify-center mt-8 greenColor"
            v-show="Doctor.DoctorLanguages != 0"
          >
            {{ $t("DoctorLanguages") }}
          </h2>

          <div class="ml-2 mt-4">
            <li
              class="mt-4"
              style="color: black"
              v-for="lang in Doctor.DoctorLanguages"
              :key="lang.ID"
            >
              {{ lang.Language.Name }}
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    imageLazy,
  },
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      Doctor: {},
      options: [],
      showAppointment: false,
      radios1: "luis",
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
     formatMonthYear(date) {
    if (!date) return '';
    const options = { year: 'numeric', month: 'short' };
    return new Date(date).toLocaleDateString(undefined, options);
  },
    getDoctorData(Id) {
      debugger;
      this.$vs.loading();
      this.$store.dispatch("DoctorList/GetDoctor", Id).then((res) => {
        debugger;
        if(res.data==undefined){

          this.Doctor = res[0].data.Data;
        }
        else{
debugger;
          this.Doctor = res.data.Data;
        }

      });
    },
  },
  computed: {
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  created() {
    this.showAppointment =
      this.$route.params.FromSurgery == undefined ||
      this.$route.params.FromSurgery == false
        ? true
        : false;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");

    var id = this.$route.params.ID;

    if (id) {
      this.getDoctorData(id);
    }
  },
};
</script>
<style>
.txt-h4 {
  font-size: 1rem !important;

  line-height: 2.2rem;
}
.SpanTextClass {
  font-size: 1rem !important;
}

.headText {
  line-height: 1.2rem;
}
.vs__clear {
  display: none;
}
.feather-chevron-down {
  display: none;
}
h2 {
  color: #004477;
}
</style>
